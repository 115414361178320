<template>
    <Head :title="title" />

    <Header />

    <main class="mt-[var(--header-page-height)] flex grow flex-col p-4">
        <slot />
    </main>

    <Footer />

    <Modal />
</template>

<script lang="ts" setup>
    import { Head } from '@inertiajs/vue3';

    import { Modal } from '@aspect/shared/composables/use-modal.ts';

    import Header from '@/app/layouts/main/partials/header.vue';
    import Footer from '@/app/layouts/main/partials/footer.vue';
    import { useTitle } from '@/app/composables/use-title.ts';

    const { title } = useTitle();
</script>
