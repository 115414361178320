<template>
    <input
        :id="id || name"
        ref="checkbox"
        v-model="proxyChecked"
        v-tippy="{ content: disabled ? disabledReason : '', trigger: 'mouseenter' }"
        :class="cn(
            'size-4 rounded text-tenant-500 shadow-sm',
            'focus:ring-0 focus:ring-offset-0 focus-visible:ring-1 focus-visible:ring-offset-0',
            !error && 'border-gray-200 focus-visible:border-tenant-500 focus-visible:ring-tenant-500',
            error && 'border-red-500 ring-1 ring-red-500 focus-visible:border-red-500 focus-visible:ring-red-500',
            disabled && 'cursor-not-allowed opacity-50',
            props.class,
        )"
        :name="name"
        :value="value"
        type="checkbox"
        @click="onClick"
    >
</template>

<!-- Inline MDN -->
<!-- name attribute is the check group/category name. -->
<!-- value attribute is the check option name. -->
<!-- id attribute is usually the same as value and there to make Label's for work -->

<script lang="ts" setup>
    import { ref, computed, watch } from 'vue';
    import { directive as vTippy } from 'vue-tippy';
    import cn from '@aspect/shared/utils/cn.ts';

    const props = withDefaults(defineProps<{
        id?: string;
        name: string;
        checked?: boolean | boolean[];
        indeterminate?: boolean;
        value?: boolean|null;
        error?: string;
        class?: string | string[];
        disabled?: boolean;
        disabledReason?: string;
        readonly?: boolean;
    }>(), {
        id: '',
        checked: false,
        indeterminate: false,
        disabled: false,
        value: null,
        readonly: false,
    });

    const emit = defineEmits(['update:checked']);
    const checkbox = ref<HTMLInputElement>();

    const proxyChecked = computed({
        get() {
            return props.checked;
        },

        set(val) {
            if (props.disabled || props.readonly) {
                return;
            }

            emit('update:checked', val);
        },
    });

    watch(() => props.indeterminate, (value) => {
        if (checkbox.value) {
            checkbox.value.indeterminate = value;
        }
    });

    function onClick(event: MouseEvent) {
        if (props.disabled || props.readonly) {
            event.preventDefault();
        }
    }
</script>
