<template>
    <AspectSelect
        v-if="divisions.length > 1"
        :auto-width="true"
        :model-value="selectedDivisionId"
        :options="divisions"
        :disabled="!isSelectable"
        class="h-auto shrink-0 bg-transparent shadow-none ring-0 focus:ring-0"
        name="division"
        tabindex="-1"
        trigger-class="px-4 hover:bg-gray-150 border-r border-gray-200 transition text-xs xl:text-base"
        @update:model-value="(value) => onUpdate(value)"
    >
        <template v-if="!isSelectable" #trigger-text>
            {{ t('All Divisions') }}
        </template>
    </AspectSelect>
</template>

<script lang="ts" setup>
    import { route } from 'ziggy';
    import { router } from '@inertiajs/vue3';

    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

    import AspectSelect from '@aspect/shared/components/aspect-select.vue';

    import type { SelectOption } from '@aspect/shared/composables/use-select.ts';

    const pageProps = usePageProps();

    const divisions = computed<SelectOption[]>(() => {
        return (pageProps.value.divisions || []).map((division) => ({
            label: division.name,
            value: division.id,
        }));
    });
    const selectedDivisionId = ref(pageProps.value.division.id);

    watch(() => pageProps.value.division.id, (id) => {
        selectedDivisionId.value = id;
    });

    function onUpdate(id: string) {
        selectedDivisionId.value = id;

        router.post(route('context.division', { division: id }), {}, {
            preserveScroll: true,
        });
    }

    const isSelectable = computed(() => {
        return pageProps.value.divisionAware;
    });
</script>
