<template>
    <div
        :class="cn(
            'relative flex w-full rounded shadow-sm bg-white ring-1 ring-gray-200',
            'transition duration-300',
            name ? `group/input-wrapper-${ name }` : 'group/input-wrapper',
            focused && 'shadow-md ring-2 ring-tenant-500 shadow-tenant-500/30',
            error && 'ring-red-300',
            error && focused && 'ring-red-500',
            disabled && 'opacity-50',
            fixedHeight && 'min-h-9',
            wrapperClass,
        )"
    >
        <div class="relative flex flex-1 items-stretch">
            <span
                v-if="prefix"
                :class="cn('flex select-none items-center pl-4', affixClass, prefixClass)"
                v-text="prefix"
            />

            <input
                v-if="mask"
                :id="id || name"
                ref="input"
                v-model="value"
                v-maska
                :class="inputClass"
                :data-maska="maska"
                :data-maska-tokens="maskaTokens.join('|')"
                :disabled="disabled"
                :name="name"
                :type="type"
                :inputmode="inputmode"
                v-bind="$attrs"
                @blur="focused = false"
                @focus="focused = true"
            >
            <input
                v-else
                :id="id || name"
                ref="input"
                v-model="value"
                :class="inputClass"
                :disabled="disabled"
                :name="name"
                :type="type"
                :inputmode="inputmode"
                v-bind="$attrs"
                @blur="focused = false"
                @focus="focused = true"
            >

            <span
                v-if="suffix"
                :class="cn('flex select-none items-center pr-4', affixClass, suffixClass)"
                v-text="suffix"
            />

            <div
                v-if="error || loading || showClearButton"
                class="absolute inset-y-0 right-0 flex items-center gap-2 px-4"
            >
                <AspectSpinner v-if="loading" class="size-4" />
                <AlertCircleIcon
                    v-if="error"
                    :key="error"
                    v-tippy="{ content: error, showOnCreate: autoShowError }"
                    aria-hidden="true"
                    class="z-10 size-4 bg-white text-red-500"
                />
                <button v-if="showClearButton" tabindex="-1" @click.stop="value = ''">
                    <XIcon class="size-4 text-gray-500" />
                </button>
            </div>
        </div>

        <slot name="after" />
    </div>
</template>

<script lang="ts" setup>
    import Big from 'big.js';
    import { ref, computed, useAttrs } from 'vue';
    import { directive as vTippy } from 'vue-tippy';
    import { vMaska } from 'maska/vue';
    import { AlertCircleIcon, XIcon } from 'lucide-vue-next';

    import cn from '@aspect/shared/utils/cn.ts';
    import { currentLocale } from '@aspect/shared/plugins/i18n.ts';

    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';

    defineOptions({
        inheritAttrs: false,
    });

    const props = withDefaults(
        defineProps<{
            name?: string;
            modelValue: null | string | number | undefined;
            type?: 'number' | 'text' | 'email' | 'password' | 'tel' | 'url' | 'search';
            inputmode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'email' | 'url' | 'search';
            id?: string;
            error?: string;
            wrapperClass?: string;
            affixClass?: string;
            prefixClass?: string;
            suffixClass?: string;
            size?: 'sm' | 'md';
            fixedHeight?: boolean;
            mask?: 'money' | 'phone' | 'phoneWithCountry' | 'postalCode' | 'percentage' | 'hex' | 'custom';
            customMask?: string;
            loading?: boolean;
            prefix?: string;
            suffix?: string;
            disabled?: boolean;
            autoShowError?: boolean;
            clearable?: boolean;
        }>(),
        {
            fixedHeight: true,
            type: 'text',
            size: 'md',
            disabled: false,
            autoShowError: false,
            clearable: false,
        },
    );

    const emit = defineEmits(['update:modelValue']);

    const attrs = useAttrs();

    // FOCUSED
    const input = ref<HTMLInputElement>();
    const focused = ref(false);

    function focus() {
        input.value?.focus();
    }

    // VALUE
    const value = computed({
        get() {
            if (props.modelValue === null || props.modelValue === undefined) {
                return '';
            }

            let value = props.modelValue.toString();

            if (props.type === 'number' && typeof props.modelValue !== 'number') {
                value = '';
            }

            if (props.mask === 'money' || props.mask === 'percentage') {
                if (value) {
                    value = new Big(value).div(100).toFixed(2);

                    // Remove zeros from the end
                    value = value.replace(/\.?0+$/, '');
                }

                value = currentLocale.value === 'fr' ? value.replace('.', ',') : value.replace(',', '.');
            }

            return value;
        },
        set(value: string | number | null) {
            if (props.mask === 'money' || props.mask === 'percentage') {
                if (currentLocale.value === 'fr') {
                    value = (value as string).replace(',', '.');
                }

                try {
                    value = new Big(value).times(100).toNumber();
                } catch (error) {
                    value = '';
                }
            }

            if (props.mask === 'postalCode') {
                value = (value as string).toUpperCase();
            }

            if (props.type === 'number') {
                if (value !== '') {
                    value = parseInt(value as string);
                } else {
                    value = null;
                }
            }

            emit('update:modelValue', value);
        },
    });


    // MASKA
    const maska = computed(() => {
        if (!props.mask) {
            return null;
        }

        return {
            money: currentLocale.value === 'fr' ? '0,##' : '0.##',
            phone: '### ###-####',
            hex: '!#HHHHHH',
            custom: props.customMask,
            phoneWithCountry: '+1 ### ###-####',
            percentage: '0.99',
            postalCode: '@#@ #@#',
        }[props.mask];
    });

    // #, @ and * also exists.
    const maskaTokens = [
        '0:[0-9]:multiple',
        '9:[0-9]:optional',
        'H:[0-9a-fA-F]',
    ];


    // INPUT CLASS
    const inputClass = computed(() => {
        return cn(
            'block w-full flex-1 border-0 placeholder:text-gray-400 bg-transparent',
            'transition duration-300',
            'focus:ring-0',

            props.size === 'sm' && 'px-1.5 py-1.5 text-2xs',
            props.size === 'md' && 'px-4 py-2 text-xs',

            prefix.value && 'pl-0',
            attrs.class as string,
            props.disabled && 'cursor-not-allowed',
        );
    });


    // PREFIX
    const prefix = computed(() => {
        if (props.mask === 'money' && currentLocale.value !== 'fr') {
            return '$';
        }

        return props.prefix;
    });


    // PREFIX
    const suffix = computed(() => {
        if (props.mask === 'money' && currentLocale.value === 'fr') {
            return '$';
        }

        if (props.mask === 'percentage') {
            return '%';
        }

        return props.suffix;
    });


    // CLEAR BUTTON
    const showClearButton = computed(() => {
        if (!props.clearable) {
            return false;
        }

        return !!props.modelValue;
    });


    const inputmode = computed(() => {
        if (props.inputmode) {
            return props.inputmode;
        }

        if (props.mask && ['percentage', 'money'].includes(props.mask)) {
            return 'decimal';
        }

        return {
            search: 'search',
            number: 'numeric',
            email: 'email',
            tel: 'tel',
        }[props.type] || 'text';
    });

    defineExpose({
        focus,
    });
</script>
