import axios from 'axios';
import { router, usePage } from '@inertiajs/vue3';
import { computed, defineAsyncComponent, defineComponent, h, nextTick, ref, shallowRef, watch } from 'vue';

import { resolver } from '@aspect/shared/plugins/modal.ts';

const modal = computed<any>(() => usePage()?.props?.modal);
const props = computed(() => modal.value?.props);
const key = computed(() => modal.value?.key);

const componentName = ref();
const component = shallowRef();
const open = ref(false);
const vnode = ref();
const nonce = ref();

function setHeaders(values: Record<string, string | null>) {
    Object.entries(values).forEach(([key, value]) =>
        ['get', 'post', 'put', 'patch', 'delete'].forEach((method) => {
            /** @ts-ignore */
            axios.defaults.headers[method][key] = value;
        })
    );
}

function resetHeaders() {
    const headers = ['X-Inertia-Modal-Key', 'X-Inertia-Modal-Redirect'];

    headers.forEach(([key]) =>
        ['get', 'post', 'put', 'patch', 'delete'].forEach((method) => {
            /** @ts-ignore */
            delete axios.defaults.headers[method][key];
        })
    );
}

function updateHeaders() {
    setHeaders({
        'X-Inertia-Modal-Key': key.value,
        'X-Inertia-Modal-Redirect': modal.value?.redirectURL,
    });

    axios.defaults.headers.get['X-Inertia-Modal-Redirect'] = modal.value?.redirectURL ?? '';
}

function close() {
    open.value = false;

    resetHeaders();
}

function resolveComponent() {
    if (nonce.value == modal.value?.nonce || !modal.value?.component) {
        return close();
    }

    if (componentName.value != modal.value?.component) {
        componentName.value = modal.value.component;

        if (componentName.value) {
            component.value = defineAsyncComponent(() => resolver.resolve(componentName.value));
        } else {
            component.value = false;
        }
    }

    nonce.value = modal.value?.nonce;
    vnode.value = component.value
        ? h(component.value, {
            key: key.value,
            ...props.value,
        })
        : '';

    nextTick(() => (open.value = true));
}

resolveComponent();

if (typeof window !== 'undefined') {
    window.addEventListener('popstate', () => {
        nonce.value = null;
    });
}

watch(modal, () => {
    if (modal.value?.nonce !== nonce.value) {
        resolveComponent();
    }
}, { deep: true });

watch(key, updateHeaders);

function redirect({ replace = false }: { replace?: boolean } = {}) {
    const redirectURL = modal.value?.redirectURL ?? modal.value?.baseURL;

    vnode.value = false;

    if (!redirectURL) {
        return;
    }

    return router.visit(redirectURL, {
        preserveScroll: true,
        preserveState: true,
        replace,
    });
}

export const useModal = () => {
    return {
        open,
        close,
        redirect,
        props,
    };
};

export const Modal = defineComponent({
    setup() {
        return () => vnode.value;
    },
});
