<template>
    <div class="pointer-events-none fixed inset-x-0 top-0 z-50 h-8 w-full bg-gradient-to-b from-gray-100 via-gray-100 to-transparent" />
    <header class="fixed inset-x-4 top-4 z-50 flex h-16 grow justify-between rounded border-gray-200 bg-white/70 ring-1 ring-gray-200 backdrop-blur-md">
        <div class="flex items-stretch">
            <AspectButtonAttached
                ref="logoComponent"
                class="rounded-l border-r border-gray-200"
                @click="toggleMainNav"
            >
                <AspectLogo class="w-48" />
            </AspectButtonAttached>
            <AppDivisionSelect />
        </div>

        <div class="flex items-stretch justify-end">
            <div
                v-if="features.active('project:dashboard-home-page')"
                class="flex items-center justify-center gap-4 border-l border-gray-200 py-4 pl-2 pr-4"
            >
                <AspectIcon name="weather/sun" class="size-16" :colored="true" />
                <div class="flex flex-col">
                    <samp class="font-sans">
                        25 °C
                    </samp>
                    <samp class="font-sans text-3xs">
                        16 °C
                    </samp>
                </div>
            </div>
            <ProfileNavItem />
        </div>
    </header>

    <Transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 -translate-x-3"
        enter-to-class="opacity-100 translate-x-0"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100 translate-x-0"
        leave-to-class="opacity-0 -translate-x-3"
    >
        <div
            v-if="mainNavVisible"
            ref="navComponent"
            :class="[
                'fixed inset-y-0 left-0 z-50 mb-4 ml-4 mt-24 flex w-64 flex-col',
                'h-[calc(100dvh_-_var(--header-page-height)_-_2rem)]' // 100dvh - header height - 2 Y margins
            ]"
        >
            <Navigation @item-click="mainNavVisible = false" />
        </div>
    </Transition>
</template>

<script setup lang="ts">
    import AspectLogo from '@aspect/shared/components/aspect-logo.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    import Navigation from '@/app/layouts/main/partials/navigation.vue';
    import ProfileNavItem from '@/app/layouts/main/partials/profile-nav-item.vue';
    import AppDivisionSelect from '@/app/components/app-division-select.vue';

    import { onClickOutside } from '@vueuse/core';
    import { useFeatures } from '@aspect/shared/composables/use-features.ts';

    const features = useFeatures();

    const mainNavVisible = ref(false);

    function toggleMainNav() {
        mainNavVisible.value = !mainNavVisible.value;
    }

    // ON CLICK OUTSIDE
    const navComponent = ref();
    const logoComponent = ref();

    onClickOutside(navComponent, () => {
        mainNavVisible.value = false;
    }, { ignore: [logoComponent] });
</script>
