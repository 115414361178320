<!-- eslint-disable vue/no-v-html -->
<template>
    <figure
        v-if="svgContent"
        :class="[
            'aspect-icon flex items-center justify-center',
            iconClass,
        ]"
        v-html="svgContent"
    />
</template>

<script lang="ts" setup>
    import { computed, onMounted, shallowRef } from 'vue';
    import useCaching from '../composables/use-caching.ts';

    const props = withDefaults(defineProps<{
        name: string;
        filled?: boolean;
        colored?: boolean;
    }>(), {
        filled: false,
        colored: false,
    });

    const { get, set } = useCaching();

    const cacheKey = computed(() => `icons.${props.name}`);
    const svgContent = shallowRef<string|null>(null);
    const svgFiles: any = import.meta.glob('../icons/**/*.svg', { query: '?raw' });

    const iconClass = computed(() => {
        if (!props.colored && !props.filled) {
            return 'fill-none stroke-current';
        }

        else if (!props.colored && props.filled) {
            return 'fill-current stroke-none';
        }

        return 'fill-inherit stroke-inherit';
    });

    onMounted(async () => {
        if (!svgFiles[`../icons/${props.name}.svg`]) {
            console.error(`[AspectIcon] "${props.name}" does not exist`);
            return;
        }

        const cachedSvg = get(cacheKey.value);

        if (cachedSvg) {
            svgContent.value = cachedSvg;
            return;
        }

        try {
            const { default: svg } = await svgFiles[`../icons/${props.name}.svg`]();

            svgContent.value = svg;
            set(cacheKey.value, svg);
        } catch (error) {
            console.error(`[AspectIcon] ${error}`);
        }
    });
</script>

<style>
    .aspect-icon svg {
        height: 100%;
        width: 100%;
    }
</style>
