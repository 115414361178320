<template>
    <figure
        :class="cn(
            'isolate relative flex items-center justify-center overflow-hidden',
            !src && aspectRatioClass,
            background && 'bg-gray-100',
            framed && 'p-1 shadow-md ring-1 ring-gray-200 bg-white',
            circle && 'rounded-full',
            wrapperClass,
        )"
    >
        <!-- Image -->
        <img
            v-if="src"
            :src="src"
            :alt="alt"
            :class="[
                'size-full',
                aspectRatioClass,
                objectFitClass,
                framed && 'ring-1 ring-gray-200',
                circle && 'rounded-full',
            ]"
        >

        <!-- No Picture Fallback -->
        <AspectIcon
            v-else
            class="size-1/2 max-w-64 text-gray-400"
            :name="fallbackIconName"
        />

        <!-- Overlay -->
        <figure
            :class="[
                'absolute inset-0 z-10 size-full select-none transition-opacity duration-300',
                overlayOpacityClass,
                overlayColorClass,
            ]"
        />
    </figure>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import cn from '@aspect/shared/utils/cn.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = withDefaults(defineProps<{
        subject?: 'generic' | 'logo' | 'person' | 'product';
        src?: string | null;
        alt?: string;
        aspectRatio?: 'full' | 'wide' | 'ultrawide'| 'superwide' | 'picture' | 'portrait' | 'square' | 'unset';
        wrapperClass?: string;
        overlayOpacity?: 0 | 5 | 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50 | 55 | 60 | 65 | 70 | 75 | 80 | 85 | 90 | 95 | 100;
        overlayColor?: 'black' | 'white' | 'tenant';
        fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
        background?: boolean;
        framed?: boolean;
        circle?: boolean;
    }>(), {
        background: undefined,
        subject: 'generic',
        overlayOpacity: 0,
        overlayColor: 'black',
    });

    const fallbackIconName = computed(() => {
        return {
            generic: 'landscape-2',
            logo: 'lens-shutter',
            person: 'user-single-neutral',
            product: 'shipping-box-2',
        }[props.subject];
    });

    const aspectRatioClass = computed(() => {
        return {
            square: 'aspect-square',
            full: 'aspect-full',
            wide: 'aspect-wide',
            ultrawide: 'aspect-ultrawide',
            superwide: 'aspect-superwide',
            picture: 'aspect-picture',
            portrait: 'aspect-portrait',
            unset: '',
        }[aspectRatio.value];
    });

    const objectFitClass = computed (() => {
        return {
            contain: 'object-contain',
            cover: 'object-cover',
            fill: 'object-fill',
            none: 'object-none',
            scaleDown: 'object-scale-down',
        }[fit.value];
    });

    const overlayOpacityClass = computed(() => {
        return {
            0: 'opacity-0',
            5: 'opacity-5',
            10: 'opacity-10',
            15: 'opacity-15',
            20: 'opacity-20',
            25: 'opacity-25',
            30: 'opacity-30',
            35: 'opacity-35',
            40: 'opacity-40',
            45: 'opacity-45',
            50: 'opacity-50',
            55: 'opacity-55',
            60: 'opacity-60',
            65: 'opacity-65',
            70: 'opacity-70',
            75: 'opacity-75',
            80: 'opacity-80',
            85: 'opacity-85',
            90: 'opacity-90',
            95: 'opacity-95',
            100: 'opacity-100',
        }[props.overlayOpacity];
    });

    const overlayColorClass = computed(() => {
        return {
            black: 'bg-black',
            white: 'bg-white',
            tenant: 'bg-tenant-500',
        }[props.overlayColor];
    });

    // PRESETS
    const aspectRatio = computed(() => {
        if (props.aspectRatio) {
            return props.aspectRatio;
        }

        return {
            logo: 'unset',
            person: 'square',
            product: 'square',
        }[props.subject] || 'square';
    });

    const fit = computed(() => {
        if (props.fit) {
            return props.fit;
        }

        return {
            logo: 'contain',
        }[props.subject] || 'cover';
    });

    const background = computed(() => {
        if (props.background !== undefined) {
            return props.background;
        }

        const value = {
            logo: false,
        }[props.subject];

        return value === undefined ? true : value;
    });
</script>
