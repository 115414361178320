import { route } from 'ziggy';
import { t } from '@aspect/shared/plugins/i18n.ts';
import { useRoute } from '@aspect/shared/composables/use-route.ts';
import { can } from '@/app/utils/authorization.ts';

export interface NavigationItem {
    name: string;
    icon?: string;
    children?: NavigationItem[];
    href: string;
    current: boolean;
    external?: boolean;
    access?: boolean;
}

export function useNavigation() {
    const { cmsRoute } = useRoute();

    const navigation = computed<NavigationItem[]>(() => [
        {
            name: t('Home'),
            icon: 'home-chimney-1',
            href: route('home'),
            current: route().current('home'),
            access: true,
        },
        {
            name: t('Schedules'),
            icon: 'calendar-schedule',
            href: route('schedules.index'),
            current: route().current('schedules.*'),
            access: can('access.schedules'),
        },
        {
            name: t('Tickets'),
            icon: 'ticket-1',
            href: route('tickets.index'),
            current: route().current('tickets.index'),
            access: true,
        },
        {
            name: t('Bundles'),
            icon: 'tickets',
            href: route('bundles.index'),
            current: route().current('bundles.index'),
            access: true,
        },
        {
            name: t('Reservations'),
            icon: 'calendar-mark',
            href: route('reservations.index'),
            current: route().current('reservations.index'),
            access: true,
        },
        {
            name: t('Customers'),
            icon: 'user-multiple-group',
            href: route('customers.index'),
            current: route().current('customers.index'),
            access: true,
        },
        {
            name: t('Memberships'),
            icon: 'crown',
            href: route('memberships.index'),
            current: route().current('memberships.index'),
            access: can('access.memberships'),
        },
        {
            name: t('Products'),
            icon: 'shipping-box-2',
            href: route('products.index'),
            current: route().current('products.*'),
            access: can('access.inventory'),
        },
        {
            name: t('Promo codes'),
            icon: 'discount-percent-coupon',
            href: route('promotions.index'),
            current: route().current('promotions.*'),
            access: true,
        },
        {
            name: t('Gift cards'),
            icon: 'gift-2',
            href: route('giftCards.index'),
            current: route().current('giftCards.*'),
            access: can('access.giftCards'),
        },
        {
            name: t('CMS'),
            icon: 'graphic-template-website-ui',
            href: cmsRoute('/'),
            current: false,
            external: true,
            access: can('access.cms'),
        },
        {
            name: t('Settings'),
            icon: 'cog-1',
            href: route('settings.edit'),
            current: route().current('settings.edit'),
            access: true,
        },
    ]);

    const filteredNavigation = computed<NavigationItem[]>(() => {
        return navigation.value.filter((item) => {
            return item.access === undefined || item.access;
        });
    });

    return { navigation: filteredNavigation };
}
